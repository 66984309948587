<template>
  <nav class="navbar navbar-dark bg-light fixed-top navbar-expand-lg toolbar"
       style="background-color:#24802e !important"><!--:style="{top: isIos ? '40px' : '0px'}"-->
    <div class="container-fluid" style="height: 100% !important">
      <div>

        <a v-if="historyCount > 0" @click="goBack()">
          <ion-icon class="mobile-activated"
                    style="color:#fff;margin-bottom: 9px !important; font-size: 2rem; vertical-align:bottom"
                    :name="'chevron-back-outline'"></ion-icon>
          <ion-icon class="mobile-deactivated"
                    style="color:#fff;margin-bottom: 4px !important; font-size: 2rem; vertical-align:bottom"
                    :name="'chevron-back-outline'"></ion-icon>
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar" style="color:#fff;" >
          <span style="margin-bottom: 9px !important;color:#fff;" class="navbar-toggler-icon"></span><span
            v-if="unreadTotal > 0"
            class="position-absolute end-1   translate-middle badge rounded-pill bg-primary"
            style="top:20px;font-size:0.6rem">
                {{ unreadTotal }}
              </span>
        </button>

        <button class="btn btn-light update-button mobile-deactivated" style="background:transparent;color:#fff;"
                type="button"
                @click="update">
          <div title="Check for updates">
            <ion-icon style="pointer-events:none;margin-right: 4px; font-size: 26px; vertical-align:bottom;color:#fff;"
                      aria-label="Check for updates" title="Check for updates"
                      :name="'sync-outline'"
                      :style="{'animation': (syncActive ? '0.75s linear infinite spinner-border' : 'none')}"></ion-icon>
          </div>

        </button>
        <router-link class="btn btn-light navbar-brand menu-conference mobile-activated"
                     style="height: 100% !important; padding-top: 0px; padding-bottom: 0px; background:transparent; border:none"
                     :to="'/app/dashboard'">
          <ion-icon style="margin-bottom: 9px !important; font-size: 26px; vertical-align:bottom; "
                    :name="'home-outline'"></ion-icon>
        </router-link>
      </div>
      <router-link class="navbar-brand menu-conference mobile-deactivated"
                   style="height: 100% !important; padding-top: 0px; padding-bottom: 0px;"
                   :to="'/app/dashboard'">
        <ion-icon style="margin-right: 4px; font-size: 26px; vertical-align:bottom" :name="'home-outline'"></ion-icon>
      </router-link>
      <router-link class="navbar-brand menu-conference mobile-activated menu-top-title"
                   style="" to="/app/dashboard"><b> </b></router-link>

      <button @click="this.resetNewsNumber(0)" style="margin-bottom: 9px !important;color:#fff;" class="navbar-toggler"
              type="button" data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbarNews"
              aria-controls="offcanvasNavbarNews" v-if="user">
        <ion-icon style="margin-right: 4px; font-size: 26px; vertical-align:bottom"
                  :name="'logo-rss'"></ion-icon>
        <div class="news-number-counter" v-if="this.newsNumber>=0"> {{ newsNumber }}</div>
      </button>
      <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar"
           aria-labelledby="offcanvasNavbarLabel">
        <button type="button" class="btn-close text-reset btn-close-white mobile-activated" data-bs-dismiss="offcanvas"
                aria-label="Close"></button>
        <div class="canvas-profile mobile-activated">
          <div class="canvas-profile-img">
                <span v-if="user">
                <img v-if="user && user.id && user.picture" class="canvas-profile-image"
                     :src="user.picture">
                   <img v-else class="canvas-profile-image" src="@/assets/user.png">
                </span>

            <img v-if="!user" class="canvas-profile-image" src="@/assets/user.png">

            <button class="btn btn-light mobile-update-button mobile-activated " type="button" @click="update">
              Data update
              <ion-icon style="margin-right: 4px; font-size: 26px; vertical-align:bottom"
                        :name="'sync-outline'"
                        :style="{'animation': (syncActive ? '0.75s linear infinite spinner-border' : 'none')}"></ion-icon>
            </button>
            <button type="button" class="btn btn-primary mobile-activated profile-button-mobile"
                    v-if="!user || !user.id" @click="login()"> Einloggen
            </button>
            <span v-if="user">
                 <button type="button" class="btn btn-primary mobile-activated profile-button-mobile" v-if="user.id"
                         @click="goToProfile()"> {{ user.firstName }} {{ user.lastName }} </button>
                 </span>
            <span v-if="user">
                 <button type="button" class="btn btn-primary mobile-activated logout-button-mobile" v-if="user.id"
                         @click="logOutAlert()">  <ion-icon
                     style="margin-bottom: 3px; margin-right:10px; font-size: 24px;"
                     :name="'log-out-outline'"></ion-icon> </button>
                </span>
          </div>
        </div>
        <div class="offcanvas-body custom-offcanvas-body">

          <div class="d-flex" v-if=" user">
            <input class="form-control me-2 mobile-activated" type="search" placeholder="Suche" aria-label="Search"
                   v-model="searchterm" @keyup.enter="search()">
            <ion-icon class="mobile-activated"
                      style="margin: 0px 8px 0 0; font-size: 40px; vertical-align:bottom;color:#356875;"
                      :name="'search-circle-outline'" @click.prevent="search()" data-bs-dismiss="offcanvas"
                      data-bs-target="offcanvasNavbar"></ion-icon>
          </div>
          <hr style="width: 100%" class="mobile-activated">
          <ul class="navbar-nav justify-content-center flex-grow-1 pe-3  mobile-activated" style="margin-left: 20px;">
            <li class="nav-item toolbar-link" @click="openLink('/app/favorites')">
              <a class="nav-link active" aria-current="page" data-bs-dismiss="offcanvas"> <i
                  class="canvas-item-icon mobile-activated ion-ios-bookmarks-outline"></i>Mein Kongress </a>
            </li>

            <!--li class="nav-item toolbar-link" @click="openLink(unreadTotal > 0 ? '/app/mychats': '/app/chat')"
                v-if="user" style="position:relative">
              <a class="nav-link active" aria-current="page" data-bs-dismiss="offcanvas"> <i
                  class="canvas-item-icon mobile-activated ion-chatbubbles"></i>Chat </a><span v-if="unreadTotal > 0"
                                                                                               class="position-absolute end-1   translate-middle badge rounded-pill bg-primary"
                                                                                               style="top:20px">
                {{ unreadTotal }}
              </span>
            </li-->
          </ul>
          <ul class="navbar-nav justify-content-center flex-grow-1 pe-3 mobile-deactivated " style="margin-left: 20px;"
              v-for="link in menulinksTop" :key="link.id">
            <li v-if="!link.sublinks && !isExternalLink(link)" class="nav-item toolbar-link"
                @click="openSubLinkOrRedirect(link)">
              <a class="nav-link active" aria-current="page" data-bs-dismiss="offcanvas"> <i
                  class="canvas-item-icon mobile-activated" :class="link.icon_style"></i> {{ link.title.en }} </a>
            </li>
            <li v-if="!link.sublinks && isExternalLink(link)" class="nav-item toolbar-link">
              <a class="nav-link active" aria-current="page" :href="link.target" target="_blank"> <i
                  class="canvas-item-icon mobile-activated" :class="link.icon_style"></i> {{ link.title.en }}</a>
            </li>

            <li v-if="link.sublinks" class="mobile-deactivated">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                 data-bs-toggle="dropdown" aria-expanded="false">
                {{ link.title.en }}
              </a>
              <div class="dropdown-menu  dropdown-desktop "
                   aria-labelledby="navbarDropdownMenuLink">
                <div v-for="link in link.sublinks" :key="link.id"><a @click="openSubLinkOrRedirect(link)"
                                                                     class="dropdown-item" style=""
                                                                     data-bs-dismiss="offcanvas">{{ link.title.en }}</a>
                </div>
              </div>

            </li>

            <li v-if="link.sublinks" class="mobile-activated">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                 style="min-width:300px"
                 data-bs-toggle="dropdown" aria-expanded="false">
                <i class="canvas-item-icon mobile-activated" :class="link.icon_style"></i> {{ link.title.en }}
              </a>
              <div style="box-shadow: none !important" class="dropdown-menu toolbar-submenu"
                   aria-labelledby="navbarDropdownMenuLink">
                <div><a v-for="link in link.sublinks" :key="link.id" @click="openSubLinkOrRedirect(link)"
                        style="color: black !important;" class="dropdown-item"
                        data-bs-dismiss="offcanvas">{{ link.title.en }} </a></div>
              </div>

            </li>

          </ul>

          <ul class="navbar-nav justify-content-center flex-grow-1 mobile-activated" style="margin-left: 20px;"
              v-for="link in menulinks" :key="link.id">
            <li v-if="!link.sublinks && !isExternalLink(link)" class="nav-item toolbar-link"
                @click="openSubLinkOrRedirect(link)"
                style="width: 200px;position:relative">
              <a class="nav-link active" aria-current="page" data-bs-dismiss="offcanvas" style="min-width:300px"> <i
                  class="canvas-item-icon mobile-activated" :class="link.icon_style"></i> {{ link.title.en }} </a><span
                v-if="link.target == '#/app/posts' && unreadNews > 0"
                class="position-absolute end-1   translate-middle badge rounded-pill bg-primary"
                style="top:20px">
                {{ unreadNews }}
              </span>
            </li>
            <li v-if="!link.sublinks && isExternalLink(link)" class="nav-item toolbar-link">
              <a class="nav-link active" aria-current="page" :href="link.target" target="_blank"
                 style="min-width:300px"> <i
                  class="canvas-item-icon mobile-activated" :class="link.icon_style"></i> {{ link.title.en }}</a>
            </li>

            <li v-if="link.sublinks" class="mobile-activated">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                 data-bs-toggle="dropdown" aria-expanded="false" style="min-width:300px">
                <i class="canvas-item-icon mobile-activated" :class="link.icon_style"></i> {{ link.title.en }}
              </a>
              <div style="box-shadow: none !important" class="dropdown-menu toolbar-submenu"
                   aria-labelledby="navbarDropdownMenuLink">
                <div v-for="link in link.sublinks" :key="link.id"><a @click="openSubLinkOrRedirect(link)"
                                                                     style="color: black !important; white-space: normal;max-width: 250px;"
                                                                     class="dropdown-item"
                                                                     data-bs-dismiss="offcanvas">{{
                    link.title.en
                  }} </a>
                  <hr class="dropdown-divider" style="border-top:none">
                </div>
              </div>

            </li>

          </ul>

          <hr style="width: 100%" class="mobile-activated">
          <ul v-if="user" class="navbar-nav justify-content-end flex-grow-1 pe-3 mobile-activated"
              style="margin-left: 20px; width: 100%">
            <li v-if="user.id" class="nav-item toolbar-link">

              <!--ul v-if="appointmentsRole" class="nav-link active" aria-labelledby="navbarDropdownMenuLink">
                <a data-bs-dismiss="offcanvas" v-if="!appointmentsRole.isConsultant" class="nav-link active"
                   href="#" @click="openLink('/app/meetings')"> <i
                    class="canvas-item-icon mobile-activated ion-android-contacts"> </i> My
                  Meetings </a>
                <a data-bs-dismiss="offcanvas" v-if="appointmentsRole.isConsultant" class="nav-link active"
                   href="#" @click="openLink('/app/consultantMeetings')"> <i
                    class="canvas-item-icon mobile-activated ion-android-contacts"> </i>
                  My Meetings </a>
                <a data-bs-dismiss="offcanvas" v-if="appointmentsRole.isExhibitor" class="nav-link active" href="#"
                   @click="openLink('/app/meetingsmanager')"> <i style="top: 13px;"
                                                                 class="canvas-item-icon mobile-activated ion-android-clipboard"> </i>
                  Meetings Manager
                  <br><span style="color:grey; margin-left: 20px; margin-top: 5px !important;"
                            v-if="appointmentsRole.exhibitorName">{{
                      appointmentsRole.exhibitorName
                    }}</span>
                </a>
              </ul-->
            </li>
          </ul>

          <ul v-if="!user || !user.id" class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <button type="button" class="nav-item btn btn-primary mobile-deactivated desktop-user-button"
                    @click="login()">
              <ion-icon style="margin-right:10px; font-size: 24px;  vertical-align: middle;"
                        :name="'person-circle-outline'"></ion-icon>
              <div class="login-button-text"> Einloggen
              </div>
            </button>
          </ul>

          <ul v-if="user && user.id" class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <div class="d-flex" v-if="user">
              <input class="form-control me-2 mobile-deactivated" type="search" placeholder="Suche" aria-label="Search"
                     v-model="searchterm" @keyup.enter="search()">
              <ion-icon class="mobile-deactivated"
                        style="margin: 0px 8px 0 0; font-size: 40px; vertical-align:bottom;color:#fff;"
                        :name="'search-circle-outline'" @click.prevent="search()"></ion-icon>
            </div>
            <li class="nav-item dropdown custom-dropdown account-dropdown mobile-deactivated">
              <a class="nav-link dropdown-toggle custom-nav-link" href="#" id="navbarDropdownMenuLink" role="button"
                 data-bs-toggle="dropdown" aria-expanded="false">
                <ion-icon style="margin-right: 4px; font-size: 23px; vertical-align:bottom"
                          :name="'person-circle-outline'"></ion-icon>
                {{ user.firstName }} {{ user.lastName }}
                <!--span v-if="unreadNews + unreadTotal > 0"
                    class="position-absolute top-0 m-3 end translate-middle badge rounded-pill bg-primary">
                {{ unreadNews + unreadTotal }}
              </span-->
                <span v-if=" unreadTotal > 0"
                      class="position-absolute top-0 m-3 end translate-middle badge rounded-pill bg-primary">
                {{ unreadTotal }}
              </span>
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li>
                  <router-link class="dropdown-item custom-dropdown-item" to="/app/userinfo"> Mein Profil</router-link>
                </li>
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li>
                  <router-link class="dropdown-item custom-dropdown-item" to="/app/favorites"> Mein Kongress
                  </router-link>
                </li>
                <!--li>
                  <hr class="dropdown-divider">
                </li>
                <li style="position:relative">
                  <router-link class="dropdown-item custom-dropdown-item" to="/app/chat"> Chat
                  </router-link>
                  <span v-if="unreadTotal > 0"
                        class="position-absolute top-0 end-0   translate-middle badge rounded-pill bg-primary">
                {{ unreadTotal }}
              </span>
                </li-->
                <li>
                  <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item custom-dropdown-item" @click="logOut()"> Ausloggen</a></li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbarNews"
         aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-header ">
        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        <span class="offcanvas-title">News</span>
      </div>
      <div class="offcanvas-body custom-offcanvas-body">
        <NewsList></NewsList>
      </div>
    </div>
  </nav>
  <Toast></Toast>
  <Splash></Splash>
  <!--ion-router-outlet id="main-content" animated="false">
  </ion-router-outlet-->
  <router-view id="main-content"></router-view>
  <!--/ion-split-pane-->

  <ChangeBrowser></ChangeBrowser>
</template>
<script>
import {
  alertController,
  //IonRouterOutlet,
  toastController,
} from "@ionic/vue";
import {mapState, mapActions, mapGetters, mapMutations} from "vuex";
import {addIcons} from "ionicons";
import {
  personCircleOutline,
  logoRss,
  syncOutline,
  logOutOutline,
  logInOutline,
  searchCircleOutline,
  homeOutline,
  chevronBackOutline
} from "ionicons/icons";
import NewsList from "@/views/News/NewsList";
import Toast from "@/components/ToastContainer";
import Splash from "@/components/Splash";

import ChangeBrowser from "@/components/ChangeBrowser";

addIcons({
  "sync-outline": syncOutline,
  "person-circle-outline": personCircleOutline,
  "log-out-outline": logOutOutline,
  "log-in-outline": logInOutline,
  "logo-rss": logoRss,
  "search-circle-outline": searchCircleOutline,
  "home-outline": homeOutline,
  "chevron-back-outline": chevronBackOutline
});
const config = require('@/config')
import {isPlatform} from '@ionic/vue';
import router from '@/router';
import {store} from "@/store";
import moment from "moment";

export default {
  components: {
    //IonRouterOutlet,
    NewsList,
    Toast,
    Splash,
    ChangeBrowser
  },
  data() {
    return {
      menulinks: [],
      menulinksTop: [],
      title: config.full_name,
      isConsultant: false,
      userConsultant: null,
      isDesktop: null,
      isIos: null,
      video_chat_enabled: false,
      confDate: config.conf_date,
      openSubBar: false,
      sublinks: null,
      time: null,
      appointmentsRole: null,
      searchterm: '',
      hideSearchBar: false,
      historyCount: 0,
      loginAlertOpen: false
    }
  },
  computed: {
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapGetters('auth', ['user']),
    ...mapState('splashscreen', ['showSplashscreen']),
    ...mapState('personalDB', ['unreadNews']),
    ...mapState('chat', ['currentChat', 'unreadTotal']),
  },
  methods: {
    ...mapActions("settings", [
      "getSetting",
    ]),
    ...mapActions('menulinks', ['getAllLinks', 'getParents']),
    ...mapActions('sync', ['start']),
    ...mapActions("exhibitors", ["getAllExhibitors"]),
    ...mapActions('menulinks', ['getAllLinks', 'getParents', 'getTopItems', 'getChildren']),
    ...mapActions('auth', ['GET_USERDATA']),
    ...mapMutations('splashscreen', ['setShowSplash']),
    ...mapActions("personalDB", ["connectPersonalDB", 'getItem', 'getItems', 'prepareFavoriteList', 'setTime', 'getUnreadNews']),
    ...mapMutations('personalDB', ['setUnreadNews']),
    ...mapMutations('news', ['resetNewsNumber']),
    ...mapActions('chat', ['getUnreadMessagesTotalCount']),

    ...mapActions('googleanalytics', ['trackWithLabel']),

    goBack() {
      let newCount = window.history.length - this.historyCount
      console.log('history length', newCount)
      //this.$router.go(-1)
      window.history.back();
      /*if(this.historyCount > 0){
        this.$router.go(-1)
      }else{
        this.$router.push('/');
      }*/
    },

    async showSyncToast() {
      const toast = await toastController
          .create({
            message: 'Application is now up to date',
            duration: 2000,
            position: 'bottom',
            animated: 'true',
            color: 'primary',
          })
      return toast.present();
    },

    async update() {
      let res = await this.start();
      if (res) {
        this.showSyncToast();
      }
    },

    async search() {

      var myOffcanvas = document.getElementById('offcanvasNavbar');
      if (myOffcanvas.classList.contains("show")) {
        myOffcanvas.classList.remove("show");
      }
      document.querySelectorAll('.offcanvas-backdrop').forEach(function (a) {
        a.remove()
      })
      this.$router.push('/app/search/' + this.searchterm);
      this.searchterm = ''
    },


    async prepareLinks() {
      this.menulinks = (await this.getParents()).sort((a, b) => a.order - b.order);
      this.menulinks.map(link => {
        try {
          link._title = JSON.parse(link.title);
        } catch (err) {
          link._title = link.title;
        }
        this.checkRedirect(link);
      });
    },
    async prepareLinksTop() {
      this.menulinksTop = (await this.getTopItems()).sort((a, b) => a.order - b.order);
      this.menulinksTop.map(link => {
        try {
          link._title = JSON.parse(link.title);
        } catch (err) {
          link._title = link.title;
        }
        this.checkRedirect(link);
      });
    },

    async checkRedirect(link) {
      if (!link.target || link.target.indexOf('undefined') != -1 || link.target == 'null') {
        link.redirect = '/app/dashboard/' + link.id;
        link.sublinks = (await this.getChildren(link.id)).sort((a, b) => a.order - b.order)

      } else {
        if (new RegExp("^(http|https)://").test(link.target)) {
          link.redirect = link.target
        } else {
          link.redirect = link.target.split('#')[1]
        }
      }
    },

    determineTarget(link) {
      if (!link.redirect) {
        this.checkRedirect(link)
      }
      return link.redirect;
      //return null;
    },

    async loginByToken(tokenSent) {
      //check for token in path
      let token = tokenSent
      if (token) {

        localStorage.setItem("x-api-key", token);

        let userRequest = await store.dispatch("auth/GET_USERDATA");
        //this.user = userRequest.data;

        let tmpUser = userRequest.data;

        if (tmpUser) {

          await store.commit("auth/SET_USER", tmpUser);
          localStorage.setItem('user', JSON.stringify(tmpUser));
        }
        //splice the path
        window.history.pushState(
            {},
            document.title,
            '/'
        );
        router.push({name: 'Dashboard'});

      } else {
        router.next();
      }
      router.next();

    },


    async login() {
      let now = new Date(moment.utc());
      console.log(now)
      /*if (now < new Date('2022-10-04')) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: 'Keine Berechtigung',
              message: '<strong>Keine Berechtigung.<br>Der Zugriff zu dieser Website ist ab 4.10.2022 möglich.</strong>',
              buttons: [
                {
                  text: 'OK',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah);
                  },
                },
              ],
            });
        return alert.present();
      } else {*/


        console.log("login")
        let self = this
        localStorage.setItem('lastPageBeforeLogin', location.hash);
        //let frontendKey = config.auth_key;
        if (isPlatform('ios') && typeof cordova != 'undefined') {
          let ref = cordova.InAppBrowser.open("https://auth.documedias.com/application/" + config.auth_dev_key, '_blank', 'location=yes', 'clearcache=no', 'clearsessioncache=no'); // eslint-disable-line

          let redirect = (param) => {
            if (param && param.indexOf('token=') != -1) {
              let token = param.split('token=')[1];
              if (token) {
                self.loginByToken(token);
                this.loginAlertOpen = false;
                ref.close();
              }
            }
          }

          ref.addEventListener('loaderror', function (event) {
            ref.executeScript({code: "document.redirectLink = (document.getElementById('redirect').href);"}, ((link) => {
              redirect(link.toString());
            }));
            if (event.url.indexOf('token=')) {
              let token = event.url.split('token=')[1];
              if (token) {
                self.loginByToken(token);
                this.loginAlertOpen = false;
                ref.close();
              }
            }
          });


        } else if (isPlatform('android') && typeof cordova != 'undefined') {

          let ref = cordova.InAppBrowser.open("https://auth.documedias.com/application/" + config.auth_dev_key, '_blank', 'location=yes', 'clearcache=no', 'clearsessioncache=no'); // eslint-disable-line
          ref.addEventListener('loaderror', function (event) {

            if (event.url.indexOf('token=')) {
              let token = event.url.split('token=')[1];
              if (token) {
                self.loginByToken(token);
                this.loginAlertOpen = false;
                ref.close();
              }
            }
          });
        } else {
          console.log("essta")
          //window.location.href = "https://auth.documedias.com/application/" + config.auth_dev_key
          //window.location.href = "https://auth.documedias.com/application/" + (process.env.NODE_ENV == 'production' ? config.auth_key : config.auth_dev_key)
          window.location.href = "https://aimgroup.eventsair.com/jahrestagung-dgho/virtual/";

        }
      //}

    },

    goToProfile() {
      this.$router.push('/app/userinfo');
    },


    async logOutAlert() {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Ausloggen',
            message: 'Sind Sie sicher, dass Sie sich ausloggen wollen?',
            buttons: [
              {
                text: 'Abbrechen',
                role: 'cancel',
                cssClass: 'secondary',
                id: 'cancel-button',
                handler: blah => {
                  console.log('Confirm Cancel:', blah)
                },
              },
              {
                text: 'OK',
                id: 'confirm-button',
                handler: () => {
                  console.log('Confirm Okay')
                  this.logOut()
                },
              },
            ],
          });
      return alert.present();
    },

    async logOut() {
      await store.commit("auth/SET_USER", null);
      localStorage.setItem('x-api-key', '');

      localStorage.setItem('user', '')

      this.$router.push('/app/dashboard');
    },

    async openSubLinkOrRedirect(link) {
      console.log(link)

      if(!this.user){
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: 'Keine Berechtigung',
              message: '<strong>Keine Berechtigung.<br>Der Zugriff zu dieser Website ist nur für eingeloggte Personen möglich.</strong>',
              buttons: [
                {
                  text: 'Zum Login',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    window.location.href = "https://aimgroup.eventsair.com/jahrestagung-dgho/virtual/";
                    console.log('Confirm Cancel:', blah);
                  },
                },
              ],
            });
        return alert.present();
      }else{
        if (link.sublinks) {
          this.openSubBar = !this.openSubBar;
          if (this.sublinks == null) {
            this.sublinks = link.sublinks
          } else {
            this.sublinks = null;
          }
        } else {
          let _link = this.determineTarget(link)
          this.trackWithLabel('menulink_clicked:' + link.title.en)

          if (new RegExp("^(http|https)://").test(_link)) {
            window.open(_link, '_blank', 'location=no').focus();
          } else {
            router.push(_link);
          }

        }
      }

    },
    isExternalLink(link) {
      return new RegExp("^(http|https)://").test(link.target)
    },

    openLink(link) {
      router.push(link);
    },

    bannerClick() {
      //router.push({name: 'Dashboard'});
      this.$router.push('/app/dashboard');
    },
    async presentNotAllowedAlert() {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Not allowed',
            message: 'You do not have permission to see the content',
            buttons: [
              {
                text: 'Ok',
                id: 'confirm-button',
                handler: () => {
                  console.log('Confirm Okay')
                  this.login()
                },
              },
            ],
          });
      return alert.present();
    }


  },

  async created() {

    this.isDesktop = isPlatform('desktop');
    this.isIos = isPlatform('ios');
    if (localStorage.getItem('x-api-key') != '') {

      let userRequest = await store.dispatch("auth/GET_USERDATA");

      if (userRequest)
        await store.commit("auth/SET_USER", userRequest.data);

      this.appointmentsRole = await store.dispatch("auth/GET_APPOINTMENT_ROLE");
      console.log("this.appointmentsRole")
      console.log(this.appointmentsRole)
    } else {
      // this.login();
    }
    //TODO:setting in config restricted and restricted marker
    if (this.user) {

      //await this.getUnreadMessagesTotalCount();
      await this.connectPersonalDB();
    }
    if (!isPlatform('mobile') || !config.is_app) {
      if (!this.user) {
        //this.login();
      } else if (this.user && !this.user.user_tn_marker) {
        //this.presentNotAllowedAlert();
      }
    }

    if (isPlatform('mobile') && config.is_app) {
      if (!this.user) {
        console.log("You need to login in order to access all data'")
        /*const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: 'Login required',
              message: 'You need to login in order to access all data',
              buttons: [
                {
                  text: 'Cancel',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah)
                  },
                },
                {
                  text: 'Go to login',
                  id: 'confirm-button',
                  handler: () => {
                    console.log('Confirm Okay')
                    this.login();
                  },
                },
              ],
            });
        return alert.present();*/

      } else if (this.user && !this.user.user_tn_marker) {
        this.presentNotAllowedAlert();
      }
    }


    this.setShowSplash(true)
    console.log(this.user)
    this.prepareLinks();
    this.prepareLinksTop();

  },

  watch: {
    updateFinished: async function (newVal) {
      console.log('FINISH')
      if (newVal) {
        await this.prepareLinks();
        await this.prepareLinksTop();
        //shows splash after every update
        //this.setShowSplash(true)


        //TODO: comment in for login
        /*if (isPlatform('mobile')){
          if (!this.user) {
            alert('You need to login in order to access all data')
            this.login();
          } else if (this.user && !this.user.user_tn_marker) {
            this.presentNotAllowedAlert();
          }
        }*/
      }
    },
    '$route': {
      immediate: true,
      async handler(route) {

        if (route.name == 'Dashboard') {
          this.historyCount = 0;
        } else {
          this.historyCount += 1;
          console.log('history', this.historyCount)
        }
        if (route.name == 'Search with term') {
          this.hideSearchBar = true;
        } else {
          this.hideSearchBar = false;
        }
      }
    }
  }

};
</script>
<style>
.menu-content-open {
  pointer-events: unset !important;
}

.menu-content.menu-content-open {
  z-index: 20;
}

.ion-menu-content-area {
  --padding-start: 0px;
  --padding-end: 0px;
}

</style>
