import axios from 'axios';

const namespaced = true;
const config = require('@/config');


const state = {

    currentRoom: null,
    userlist: [],
    roomlist: [],
    currentMessages: [],
    unreadMessages: 0,
    chatUser: {},
    unreadTotal: 0
}

const actions = {
    async registerUser({commit}, _user) {
        console.log('REG', _user)
        let user = {};
        try {
            user = (await axios.get(config.chat_url + 'user/' + _user.id, {
                headers: {
                    "x-api-key": localStorage.getItem('x-api-key')
                }
            })).data;
        } catch (err) {
            let company = _user && _user.extra && _user.extra[config.conference] && _user.extra[config.conference].company ? _user.extra[config.conference].company : '';
            let params = {
                id: _user.id,
                firstname: _user.firstName,
                lastname: _user.lastName,
                avatar_id: _user.picture ? _user.picture.split('/')[4] : '',
                additional_content: {'usertype': 'user', 'company': company}
            }
            console.log(params)
            user = (await axios.post(config.chat_url + 'user', params, {
                headers: {
                    "x-api-key": localStorage.getItem('x-api-key')
                }
            })).data;
        }
        console.log(user)
        commit('setChatUser', user)
    },
    async forceUserCreate({state}, _user) {
        console.log('REG', _user, state.chatUser)
        let user = {};
        try {
            user = (await axios.get(config.chat_url + 'user/' + _user.id, {
                headers: {
                    "x-api-key": localStorage.getItem('x-api-key')
                }
            })).data;
        } catch (err) {
            let params = _user;
            user = (await axios.post(config.chat_url + 'user', params, {
                headers: {
                    "x-api-key": localStorage.getItem('x-api-key')
                }
            })).data;
        }
        console.log(user)
    },

    async getUserList({commit}, user) {
        let list = (await axios.get(config.chat_url + 'user', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        })).data;

        let tmp = list.sort((a, b) => {
            return a.lastname.localeCompare(b.lastname);
        });
        tmp = tmp.filter(u => {
            return u.additional_content.usertype == 'user' && u.id != user.id
        });
        commit('setUserList', tmp)

    },

    async getRoomList({commit}) {
        let list = await axios.get(config.chat_url + 'room', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        });
        console.log(list)
        commit('setRoomList', list.data)
    },

    async getMessagesInRoom({commit, state}) {
        let messages = (await axios.get(config.chat_url + 'message/room/' + state.currentRoom + '/list', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        })).data;
        commit('setCurrentMessages', messages)
    },

    async getUnreadMessagesTotalCount({commit}) {

        let unreadCount = (await axios.get(config.chat_url + 'message/unread/count', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        })).data;
        console.log('getunreads', unreadCount)
        commit('setUnreadCountTotal', unreadCount)
    },

    async enterRoom({commit}, ids) {
        //TODO:check if room exists
        let params = {
            "name": "Privat Test",
            "users": ids
        }

        let room = (await axios.post(config.chat_url + 'room', params, {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        })).data;
        console.log(room)
        commit('setCurrentRoom', room.id)
    },

    async sendMessage({state}, text) {
        let params = {
            text: text,
            from_user_id: state.chatUser.id,
            room_id: state.currentRoom
        }

        await axios.post(config.chat_url + 'message', params, {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        });
    },
    async markMessageAsRead({state}, id) {
        console.log(state)
        await axios.put(config.chat_url + 'message/' + id + '/read', {
            headers: {
                "x-api-key": localStorage.getItem('x-api-key')
            }
        });
    },


}
const mutations = {
    setCurrentRoom(state, currentRoom) {
        state.currentRoom = currentRoom;
    },

    setUserList(state, userlist) {
        state.userlist = userlist;
    },

    setChatUser(state, user) {
        state.chatUser = user;
    },

    setRoomList(state, roomlist) {
        state.roomlist = roomlist;
    },

    setUnreadMessages(state, messagecount) {
        state.unreadMessages = messagecount;
    },
    setUnreadCountTotal(state, messagecount) {
        state.unreadTotal = messagecount;
    },

    setCurrentMessages(state, messages) {
        state.currentMessages = messages;
    },

    addMessage(state, message) {
        let msg = state.currentMessages.find(m => m.id == message.id);
        if (!msg) {
            state.currentMessages.push(message)
        }
    }
}
const getters = {}

export const chat = {
    namespaced,
    state,
    getters,
    mutations,
    actions
}
