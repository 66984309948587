<template>
  <div id="splash" v-if="show" :style="getStyle(splash)" style=""></div>
</template>
<script>
import {defineComponent} from 'vue';
import {mapState, mapActions, mapGetters, mapMutations} from "vuex";

export default defineComponent({
  name: 'Splash',
  data() {
    return {
      splash: {},
      splash_second: {},
      splash_file: null,
      second_splash_file: null,
      splash_port: {},
      splash_second_port: {},
      splash_land: {},
      splash_second_land: {},
      splash_duration: 0,
      splash_second_duration: 0,
      landscape: true,
      width: (window.innerWidth
          || document.documentElement.clientWidth
          || document.body.clientWidth),
      height: (window.innerHeight
          || document.documentElement.clientHeight
          || document.body.clientHeight),
      show: false
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('splashscreen', ['showSplashscreen']),
  },
  methods: {
    ...mapActions('banners', ['getForState']),
    ...mapActions("settings", [
      "getSetting",
    ]),
    ...mapActions("files", [
      "getImage",
    ]),
    ...mapMutations("splashscreen", [
      "setShowSplash",
    ]),

    getStyle(splash) {
      return {
        'background-image': 'url(' + splash || '' + ')',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
        'background-size': 'contain'
      }
    },

    async showSplash(_splash, duration, checknext) {
      this.splash = _splash;
      this.show = true
      setTimeout(() => {
        if (this.splash_second_duration > 0 && checknext) {
          this.showSplash(this.splash_second, (this.splash_second_duration || 3000), false);
        } else {
          this.splash = '';
          this.show = false
          this.setShowSplash(false);
        }
      }, parseInt(duration) * 1000);
    },

    prepareDimension(fileId, splash_count) {
      let baseurl = 'https://fileserver.documedias.systems/file/' + fileId + '/resize/'
      let ratio = 0;
      if (!this.landscape ? this.width / this.height >= ratio : this.height / this.width >= ratio) {

        this[splash_count + '_port'] = baseurl + this.width + '/' + this.height + '/' + (window.devicePixelRatio || 1)
        this[splash_count + '_land'] = baseurl + this.width + '/' + this.height + '/' + (window.devicePixelRatio || 1)
      } else {

        this[splash_count + '_port'] = baseurl + this.width + '/' + this.width / ratio + '/' + (window.devicePixelRatio || 1)
        this[splash_count + '_land'] = baseurl + this.width + '/' + this.width * ratio + '/' + (window.devicePixelRatio || 1)
      }
    },

    async initSplash() {
      try {
        this.splash_file = await this.getSetting('splashscreen');
        this.second_splash_file = await this.getSetting('splashscreen_second');
        this.prepareDimension(this.splash_file, 'splash')
        this.prepareDimension(this.second_splash_file, 'splash_second')
        this.splash = this.landscape ? this.splash_land : this.splash_port;
        this.splash_second = this.landscape ? this.splash_second_land : this.splash_second_port;
        this.splash_duration = await this.getSetting('splashscreen_duration');
        this.splash_second_duration = await this.getSetting('splashscreen_second_duration');
        console.log('splashfile', this.splash)
      } catch (err) {
        this.setShowSplash(false);
      }

    }
  },
  async created() {
    await this.initSplash()
    /*await this.initSplash()
    if(this.user){
      if(this.splash_file){
        this.showSplash(this.splash, this.splash_duration, (this.second_splash_file != null && this.splash_second_duration > 0))
      }
    }*/
  },
  watch: {
    showSplashscreen: async function (newVal) {
      if (newVal == true) {
        console.log('showit', newVal)
        await this.initSplash()
        if(this.splash.indexOf('https://fileserver.documedias.systems/file/cc194fe0-0b45-11e8-bfeb-0119d669369a') == -1){
          this.showSplash(this.splash, this.splash_duration, (this.second_splash_file != null && this.splash_second_duration > 0))
        }
      } else {
        console.log('no newval')
      }
    },
  }
});
</script>
<style lang="scss" scoped>
#splash {
  position: absolute;
  background-color: white !important;
  z-index: 1040; //999;
  width: 100%;
  height: 100%;

}
</style>
