//variablen muessen pro instanz angepasst werden
module.exports = {
    baseUrl: 'https://mantel-api.mobile.documedias.systems/v2/checkall/',
    baseUrlDownload: 'https://mantel-api.mobile.documedias.systems/v2/downloadall/',
    baseUrlCDN: "https://documedias-188ab.kxcdn.com/",
    cdnInitStamp: "2022-04-14T07:10:33.808Z",
    conference: '1340',
    short_name: 'dgho2022',
    full_name: 'DGHO 2022',
    api_version: 2, //change this to api version 2 for new conferences!!!
    conf_date : '27.-30. April',
    api_url : 'https://coredev.api.documedias.systems/api/',
    appointment_api_url : 'https://dev.appointment.documedias.systems/api/',
    auth_url : 'https://auth.documedias.com/',
    auth_key : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbklkIjoxMzk2LCJjb25ncmVzc0lkIjoxMzQwLCJkYXRlIjoiMjAyMi0wNi0yMFQwOTowNToyOS4xNDFaIiwiaWF0IjoxNjU1NzE1OTI5fQ.o2f0KSYtE0-DPHZcyXsoQfhybmP_tl94nqS3AH-LY1o',
    auth_dev_key : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbklkIjoxMzk1LCJjb25ncmVzc0lkIjoxMzQwLCJkYXRlIjoiMjAyMi0wNi0yMFQwOTowNTowMy45NDFaIiwiaWF0IjoxNjU1NzE1OTAzfQ.i5mBAriqLh4h5ZDjm4LHcxf6zkZ9IEnC5cRWsvVwxl0',
    vue_app_access_key : 'eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJhcHBsaWNhdGlvbklkIjo5LCJjb25ncmVzc0lkIjoxLCJkYXRlIjoiMjAxOC0xMC0wMVQwOTo0Njo0MC42MDlaIiwiaWF0IjoxNTM4Mzg3MjAwfQ.1Z8vRJF2gZtCuLDEWxQhNyvlYHS6YZsdJxvoYGfyo112afY8CofuEBP4_jvIPXhc',
    chat_url: 'https://dgho2022.chat.documedias.systems/api/',
    is_app: false, //unbedingt anpassen bevor App deploy,
    keycdn: 'd-188ab.kxcdn.com'
}
