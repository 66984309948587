<template>
  <ion-slides v-if="banners.length" pager="false"
              :options="{'loop': banners.length > 1 ? 'true' : 'false', 'autoplay': banners.length > 1 ? {'delay': 5000} : 'false','direction':direction}"
              class="banner-wrapper"
              :style="{'border-bottom': position == 'top' ? '1px solid lightgrey' : 'none', 'border-top': position == 'bottom' ? '1px solid lightgrey' : 'none'}">
    <ion-slide class="swiper-no-swiping" v-for="banner in banners" :key="banner.id">
      <a :href="banner.target_link" style="height:100%" @click="trackBanner(banner.name)">
        <img :src="banner.file">
      </a>

    </ion-slide>
  </ion-slides>
</template>
<script>
import {IonSlides, IonSlide} from '@ionic/vue';
import {defineComponent} from 'vue';
import {mapActions} from "vuex";

export default defineComponent({
  name: 'Banner',
  data() {
    return {
      banners: []
    }
  },
  props: ['direction', 'position'],
  components: {
    IonSlides,
    IonSlide
  },
  methods: {
    ...mapActions('banners', ['getForState', 'getAll']),
    ...mapActions('googleanalytics', ['trackWithLabel']),
    trackBanner(banner) {
      console.log('Banner viewed:', banner)
      this.trackWithLabel('Banner viewed:' + banner);
    },
  },
  async created() {
    this.banners = await this.getForState({location: location.hash, position: this.position});
    //this.banners = await this.getAll();
    //console.log('banners', this.banners)
  },
  watch: {
    '$route': {
      // with immediate handler gets called on first mount aswell
      immediate: true,
      // handler will be called every time the route changes.
      // reset your local component state and fetch the new data you need here.
      async handler(to) {
        this.banners = await this.getForState({location: to.href, position: this.position});
        //this.banners = await this.getAll();
        //console.log('banners', this.banners,to)
      }
    }
  }
});
</script>
<style lang="scss" scoped>
.banner-wrapper {
  height: 100px;
  color: #000;
  width: 100%;

  .swiper-no-swiping {
    img {
      padding: 5px;
    }
  }
}

@media (max-width: 768px) {
  .banner-wrapper {
    height: 80px;
    color: #000;
    width: 100%;

    .swiper-no-swiping {
      img {
        padding: 5px;
      }
    }
  }
}
</style>
