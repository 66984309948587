<template>
  <base-layout v-if="isExplorer"> 
  <header-bar></header-bar>
    <ion-content has-header="true">
      <div class="cbrowser-title"> Please use a supported web browser: </div>
        <table class="cbrowser-table">
        <tr class="cbrowser-row">
          <td><a href="https://www.google.com/intl/en/chrome/" >Google Chrome </a> </td>
        </tr>
        <tr class="cbrowser-row">
          <td><a href="https://www.mozilla.org/en/firefox/new/" >Firefox </a></td>
        </tr>
        <tr class="cbrowser-row">
          <td><a href="https://www.opera.com" >Opera</a></td>
        </tr>
      </table>

    </ion-content>
  </base-layout>
</template>

<script>
import {defineComponent} from 'vue';


export default defineComponent({
  name: 'ChangeBrowser',
  data() {
    return {
      isExplorer: false,
      browserLinks: ['Chrome','Chrome','Chrome','Chrome']
    }
  },

  methods:{
  },

 

  async created(){
                console.log("detecting browser")
         let userAgent = navigator.userAgent;
         console.log(userAgent)
         if(userAgent.match(/chrome|chromium|crios/i)){
             this.browserName = "chrome";
           }else if(userAgent.match(/firefox|fxios/i)){
             this.browserName = "firefox";
           }  else if(userAgent.match(/safari/i)){
             this.browserName = "safari";
           }else if(userAgent.match(/opr\//i)){
             this.browserName = "opera";
           } else if(userAgent.match(/edg/i)){
             this.browserName = "edge";
             this.isExplorer = true;
                 alert("Please use another browser. Chrome, Firefox, Opera or Safari are valid options")
           }else{
             this.browserName="No browser detection";
           }
      
  }


});
</script>
<style >


      
      
</style>